import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Header from "../components/header"
import Seo from "../components/seo"

// markup
const HiyoriteiPage = () => {
  return (
    <div>
      <Seo title="HISTORY - 小幡楼の歴史" />
      <main id="wrap">
        <Header />
        <article className="main-col history" role="main">
          <div className="main-wrap">
            <div className="key-visual">
              <div className="main-img">
                <div className="img">
                  <StaticImage 
                    layout="fullWidth"
                    alt="小幡楼の歴史メイン画像"
                    src="../images/history/mv.jpg"
                    className="pc"
                  />
                  <StaticImage 
                    layout="fullWidth"
                    alt="アクセスメイン画像"
                    src="../images/history/mv-sp.jpg"
                    className="sp"
                  />
                  <div className="logo-text sp">
                    <h1>割烹小幡の歴史</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="contents-4" className="c-contents__history">
            <div className="c-contents__history-wrap">
              <div className="c-contents__history-text">
                <h2 className="pc">割烹小幡の歴史</h2>
                <p>割烹小幡の創業は、1876年(明治9年)といわれております。港や日本海、市街を一望できる景勝地にあることから「瞰海楼（かんかいろう）」と呼ばれ、高名な政治家や多くの文人墨客が足跡をとどめました。<br />小幡家の17代目小幡善吉が明治3年(1870年)に船宿を開業し、18代目小幡なをが小幡を創業。明治27年(1894年)に起きた酒田大地震では、酒田の市街地は大きな被害を受け、小幡の周辺では大火災も発生。小幡も火災の被害により一時期休業いたしました。<br />震災前に小幡に滞在した著名人は多岐にわたり、板垣退助や皇族も訪れていることから、酒田におけるトップクラスの宿泊場所と推測されています。<br />
                <span className="img sp">
                  <StaticImage 
                    layout="constrained"
                    alt="小幡瞰海楼より見える最上川と酒田港"
                    src="../images/history/img01.jpg"
                  />
                  <span className="sans">小幡瞰海楼より見える最上川と酒田港</span>
                </span>
                震災後には、宿泊のない料理専門店として営業再開。大正11年(1922年)に洋館を新築し、東京の精養軒で修業した料理人を迎えて本格的なフレンチレストランを目指す洋食部を新設。<br />戦時中は営業を停止しておりましたが、和館は戦後営業を再開し、眺めの良い２階の大座敷や１階の小上がり座敷などで料亭としての営業を行っておりました。<br />
                <span className="img sp">
                  <StaticImage 
                    layout="constrained"
                    alt="明治時代に建てられた小幡料亭部分"
                    src="../images/history/img02.jpg"
                  />
                  <span className="sans">明治時代に建てられた小幡料亭部分</span>
                </span>
                平成に入り、全国的にも多くの老舗料亭が廃業していく中、小幡も平成10年(1998年)に閉店。<br />平成21年(2009年)に米国アカデミー賞を受賞した映画「おくりびと」のロケ地となったことで一躍脚光を浴び、その後、建物の一部を展示施設として観光客に開放され、平成24年(2012年)に小幡家から酒田市に寄贈されました。
                <span className="img sp">
                  <StaticImage 
                    layout="constrained"
                    alt="港側に面した小幡料亭部分"
                    src="../images/history/img03.jpg"
                  />
                  <span className="sans">港側に面した小幡料亭部分</span>
                </span>
                </p>
              </div>
              <div className="pc">
                <div className="c-contents__history-img">
                  <div className="img">
                    <StaticImage 
                      layout="constrained"
                      alt="小幡瞰海楼より見える最上川と酒田港"
                      src="../images/history/img01.jpg"
                    />
                    <p>小幡瞰海楼より見える最上川と酒田港</p>
                  </div>
                  <div className="img">
                    <StaticImage 
                      layout="constrained"
                      alt="明治時代に建てられた小幡料亭部分"
                      src="../images/history/img02.jpg"
                    />
                    <p>明治時代に建てられた小幡料亭部分</p>
                  </div>
                  <div className="img">
                    <StaticImage 
                      layout="constrained"
                      alt="港側に面した小幡料亭部分"
                      src="../images/history/img03.jpg"
                    />
                    <p>港側に面した小幡料亭部分</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="c-footer__wrap">
              <ul>
                <li><Link className="menu-item store-info" to="/">日和亭 小幡楼</Link></li>
                <li><Link className="menu-item store-info" to="/bakerycafe/">ヒヨリベーカリー＆カフェ</Link></li>
                <li><Link className="menu-item store-info" to="/hiyoritei/">日和亭</Link></li>
                <li><Link className="menu-item store-info" to="/access/">アクセス</Link></li>
                <li><Link className="menu-item store-info" to="/history/">割烹小幡の歴史</Link></li>
              </ul>
            </div>
            <small className="sans">&copy; 2021 obatarou</small>
          </footer>
        </article>
      </main>
    </div>
  )
}

export default HiyoriteiPage
